import firebase from "firebase/app";
import "firebase/messaging";
import {toast} from '../src/components'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCqFIrJTY2R__3YfZEdpX46SCjkKhS2jZw",
  authDomain: "alcooly-store-panel.firebaseapp.com",
  projectId: "alcooly-store-panel",
  storageBucket: "alcooly-store-panel.appspot.com",
  messagingSenderId: "1401941890",
  appId: "1:1401941890:web:fe9f5378d8c3a8ef47699b",
  measurementId: "G-4MYLBTPECD",
};
firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging();

const { REACT_APP_VAPID_KEY } = process.env;
const publicKey =
  "BO_Mn-0tIANQ61f9izCF8kUf5N-RRk0tl3Lg02p1G0N7ATQnNt1qXfpnDbyWHhfJLSa8smfEDAInVe1sqc1z7-0";

export const getToken = async (setTokenFound) => {
  let currentToken = "";

  try {
    currentToken = await messaging.getToken({ vapidKey: publicKey });
    if (currentToken) {
      setTokenFound(true);
    } else {
      setTokenFound(false);
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
    const Notification =window.Notification

    // if (Notification.permission !== "granted"){
    // Notification.requestPermission();
    // toast.warning("Please Enable notification for updates", { autoClose: 15000 });
    // }
  
  }

  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
