import React from "react";
import { withRouter } from "react-router-dom";
const ContainerHeader = ({ title, id, icon = "", match }) => {
  const path = match.path.substr(1);
  const subPath = path.split("/");
  function goBack() {
    window.history.back();
  }
  return (
    <div className="page-heading d-sm-flex justify-content-sm-between align-items-sm-center">
      <h3 className="title mb-3 mb-sm-0">
        {/*<Tooltip title="Back" placement="left">
        <ArrowBackIosIcon style={{fontSize: 'smaller',fontWeight: 'bold',cursor: 'pointer'}} onClick={() => goBack()} />
        </Tooltip>*/}
        <i class={`zmdi ${icon} zmdi-hc-fw`}></i>

        {title}
      </h3>
      {id && <h6 className="title mb-3 mb-sm-0"># {id}</h6>}
    </div>
  );
};

export default withRouter(ContainerHeader);
