import React from "react";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import {
  withRouter,
  connect,
  TEXTFIELD,
  ToastContainer,
  toast,
  Axios,
  PASSWORDFIELD,
  SUBMIT,
  RESET,
  CircularProgress,
  Logo,
  Row,
  Col,
  ApiUrl,
} from "../../components/index";

import {
  hideMessage,
  showAuthLoader,
  userSignInSuccess,
  hideAuthLoader,
} from "actions/Auth";
const { API_LOGIN } = ApiUrl;

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = (theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(https://source.unsplash.com/random)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class SignIn extends React.Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      disabled: false,
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  loginUser = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
      disabled: true,
    });
    this.props.showAuthLoader();
    const { email, password } = this.state;
    const formData = {
      email: email.trim(),
      password: password.trim(),
      firebaseToken: localStorage.getItem("firebaseToken") || "none",
    };
    Axios.post(API_LOGIN, formData)
      .then((response) => {
        let data = response.data;
        console.log(data.data.role,"data.role")
        if (data.status == "failure") {
          toast.error(data.message, {
            toastId: "customId",
          });
        } else {
          data = data.data;
          console.log(data, 6878798799878);
          localStorage.setItem("token", data.token);
          localStorage.setItem("_id", data._id);
          localStorage.setItem("subscriptionId", data.subscriptionId);
          localStorage.setItem("name", data.name);
          localStorage.setItem("role", data.role);
          localStorage.setItem("storeRole", data.storeRole);
          localStorage.setItem("permission",JSON.stringify(data.permission));
          localStorage.setItem("categories", data.categories[0]);
          localStorage.setItem("user_id", data.token);
          localStorage.setItem("storeUniqueId", data.storeUniqueId);
          localStorage.setItem("userType", data.userType ? 1 : 0);
          localStorage.setItem("restaurantStatus", data.restaurantStatus);
          this.props.userSignInSuccess(data.token);
          toast.success("Login successfully.");
          console.log( data.storeRole,"data.role,987")

          setTimeout(() => {
            if (data && !data.accountDetails && data.storeRole  ==="SupperOwner") {
              return this.props.history.push("/app/add-account/add");
            } else {
              return this.props.history.push("/app/dashboard/index");
            }
          }, 1000);

          // setTimeout(() => {
          //   this.props.history.push("/app/dashboard/index");
          // }, 1000);
        }
        this.setState({
          loading: false,
          disabled: false,
        });
        this.props.hideAuthLoader();
      })
      .catch((err) => {
        let error = err.response;
        if (error !== undefined) {
          toast.error(error.data.message);
        } else {
          toast.error("server error, Please try after some time.", {
            toastId: "customId",
          });
        }
        this.setState({
          loading: false,
          disabled: false,
        });
        this.props.hideAuthLoader();
      });
  };

  reset = () => {
    this.setState({
      email: "",
      password: "",
    });
  };

  render() {
    const { email, password, loading, disabled } = this.state;
    const { classes } = this.props;

    return (
      <Grid
        container
        component="main"
        className={classes.root}
        style={{ background: "white" }}
      >
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={4}
          container
          justify="center"
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          className="login-page-sidebar"
        >
          <img
            src={require("assets/images/logIn.jpg")}
            className="logo-login"
            style={{ height: "300px", width: "300px", objectFit: "contain" }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={8}
          md={8}
          component={Paper}
          elevation={6}
          square
          alignContent="center"
          style={{ maxHeight: "100vh", overflow: "scroll" }}
        >
          <div className={classes.paper}>
            {/* <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar> */}
            <Grid
              item
              xs={false}
              sm={4}
              md={12}
              container
              justify="center"
              spacing={0}
              direction="column"
              alignItems="center"
              justify="center"
              className="login-page-sidebar"
            >
              <img
                src={require("assets/images/logo.png")}
                className="logo-login"
                style={{
                  height: "60px",
                  width: "60px",
                  objectFit: "contain",
                }}
              />
            </Grid>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Row>
              <Col sm="12" md={{ offset: 2, size: 8 }}>
                <form
                  className={classes.form}
                  noValidate
                  onSubmit={(e) => this.loginUser(e)}
                >
                  <TEXTFIELD
                    label="Enter Email"
                    fullWidth
                    name="email"
                    onChange={this.handleChange}
                    value={email}
                    errors={{}}
                  />
                  <PASSWORDFIELD
                    label="Password"
                    fullWidth
                    name="password"
                    onChange={this.handleChange}
                    value={password}
                    errors={{}}
                  />
                  {/* <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember me"
                  /> */}
                  <SUBMIT title="Sign In" disabled={disabled} />
                  <Grid container>
                    <Grid item xs>
                      <Link href="#/forgot-password" variant="body2">
                        Forgot password?
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link href="#/register" variant="body2">
                        {"Don't have an account? Sign Up"}
                      </Link>
                    </Grid>
                  </Grid>
                </form>
              </Col>
            </Row>
            <ToastContainer />
          </div>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps, {
  hideMessage,
  showAuthLoader,
  userSignInSuccess,
  hideAuthLoader,
})(withStyles(useStyles)(SignIn));
