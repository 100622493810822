import React, { Component } from "react";
import OtpVerify from "./otpVerify";
import Registor from "./register";
import SubscritionList from "./subscriptionList";
import {
  storeRegistrationDetails,
  registrationStep,
} from "../../../actions/Auth";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core";
import NotFound from "../../../app/routes/extraPages/routes/404";
import { connect } from "react-redux";
import AddBankDetails from "./AddBankDetails";

const useStyles = (theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(https://source.unsplash.com/random)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});
class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      step1: {},
      step2: {},
      step3: {},
    };
  }

  nextStep = () => {
    this.setState((pre) => ({
      ...pre,
      step: pre.step + 1,
    }));
  };
  callback = (data, step) => {
    this.setState({
      allStep: data,
    });
  };
  render() {
    const { regsitrationSteps } = this.props;
    console.log(regsitrationSteps === 2, "098908908908");
    const { step } = this.state;
    if (regsitrationSteps == 1) {
      return (
        <Template {...this.props}>
          <Registor
            nextStep={this.nextStep}
            callback={this.callback}
            state={this.state}
          />
        </Template>
      );
    } else if (regsitrationSteps == 2) {
      return (
        <Template {...this.props}>
          <OtpVerify
            nextStep={this.nextStep}
            callback={this.callback}
            state={this.state}
          />{" "}
        </Template>
      );
    }
    // else if (regsitrationSteps == 3) {
    //   return (
    //     <Template {...this.props}>
    //       <SubscritionList
    //         nextStep={this.nextStep}
    //         callback={this.callback}
    //         state={this.state}
    //       />{" "}
    //     </Template>
    //   );
    // }
    else if (regsitrationSteps == 3) {
      return (
        <Template {...this.props}>
          <AddBankDetails
            nextStep={this.nextStep}
            callback={this.callback}
            state={this.state}
          />{" "}
        </Template>
      );
    } else {
      return <NotFound />;
    }
  }
}

const mapStateToProps = ({ auth }) => {
  const {
    loader,
    alertMessage,
    showMessage,
    authUser,
    regsitrationSteps,
  } = auth;
  console.log(auth.regsitrationSteps, 7889789879);
  return { loader, alertMessage, showMessage, authUser, regsitrationSteps };
};

export default connect(mapStateToProps, {
  registrationStep,
})(withStyles(useStyles)(SignUp));

export const Template = (props) => {
  const { classes } = props;
  return (
    <>
      <Grid container component="main" className={classes.root}>
        <Grid
          item
          xs={false}
          sm={4}
          md={4}
          container
          justify="center"
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          className="login-page-sidebar"
        >
          <img
            src={require("assets/images/logInBanner.jpg")}
            className="logo-login"
            style={{ height: "100%", width: "500px", objectFit: "cover" }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={8}
          md={8}
          component={Paper}
          elevation={6}
          square
          alignContent="center"
          style={{ maxHeight: "100vh", overflow: "scroll" }}
        >
          {props.children}
        </Grid>
      </Grid>
    </>
  );
};
