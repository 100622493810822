import React from "react";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import OtpVerify from "./otpVerify";

import {
  withRouter,
  connect,
  TEXTFIELD,
  Button,
  ToastContainer,
  toast,
  Axios,
  Col,
  ApiUrl,
  Input,
  InputLabel,
  MenuItem,
  Row,
  FormControl,
  checkError,
  checkValidations,
} from "../../../components";

import {
  hideMessage,
  showAuthLoader,
  userSignInSuccess,
  hideAuthLoader,
} from "actions/Auth";
import {
  storeRegistrationDetails,
  registrationStep,
} from "../../../actions/Auth";

const { API_ADD_ACCOUNT, API_GET_STORES_BY_ID } = ApiUrl;
const useStyles = (theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(https://source.unsplash.com/random)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class AddBankDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      bankName: "",
      routingNumber: "",
      accountNumber: "",
      loading: false,
      errors: {
        name: "",
        bankName: "",
        routingNumber: "",
        accountNumber: "",
      },
      disabled: true,
    };
  }

  componentDidMount() {
    const { restaurantId } = this.state;
    Axios.get(API_GET_STORES_BY_ID + restaurantId)
      .then((res) => {
        let data = res.data.data;
        const { accountDetails } = data;

        this.setState({
          ...accountDetails,
        });
        console.log(
          data.categories.map((c) => c._id),
          11111
        );
      })
      .catch((err) => {
        console.log("Server error", err);
      });
  }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value, validations } = event.target;
    let finalVal = value;
    let errors = this.state.errors;
    switch (name) {
      case "name":
        errors.name = value.length < 1 ? "Required" : "";
        break;
      case "bankName":
        errors.bankName = value.length < 1 ? "Required" : "";
        break;
      case "accountNumber":
        errors.accountNumber = value.length < 1 ? "Required" : "";
        break;
      case "routingNumber":
        errors.routingNumber = value.length < 1 ? "Required" : "";
        break;

      default:
        errors[name] = value.length < 1 ? "Required" : "";
        break;
    }

    this.setState({ errors, [name]: finalVal }, () => {
      let err = checkError(errors);
      console.log(checkError, errors, "9899778");
      this.setState({ disabled: err.disabled });
    });
  };

  redirectPage = () => {
    // this.props.history.push("/otp-verify");
    this.props.registrationStep(2);
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ loading: true, disabled: true });
    const {
      name,
      errors,
      bankName,
      routingNumber,
      accountNumber,
      process,
    } = this.state;
    let data = {
      name: name,
      bankName: bankName,
      routingNumber: routingNumber,
      accountNumber: accountNumber,
    };
    let valid = checkValidations(data);
    if (valid.error) {
      toast.error(valid.error);
      this.setState({ loading: false, disabled: false });
      return false;
    }

    data = valid.data;
    let url = API_ADD_ACCOUNT;
    Axios.post(url, data)
      .then((res) => {
        let response = res.data;
        console.log(res, 65456456564);
        const { status, message } = response;
        console.log(status);
        if (status === "failure") {
          // if (status === "failure") {
          toast.error(message);
          this.setState({ loading: false, disabled: false });
        } else {
          toast.success(response.message);
          setTimeout(() => {
            this.redirectPage();
          }, 100);
        }
      })
      .catch((error) => {
        let res = error.response;
        toast.error("Error");
        this.setState({ loading: false, disabled: false });
      });
  };
  reset = () => {
    this.setState({
      name: "",
      bankName: "",
      routingNumber: "",
      accountNumber: "",
      loading: false,
      errors: {
        name: "",
        bankName: "",
        routingNumber: "",
        accountNumber: "",
      },
      disabled: true,
    });
  };

  render() {
    const {
      name,
      bankName,
      accountNumber,
      routingNumber,
      errors,
      disabled,
      loading,
    } = this.state;
    const { classes } = this.props;

    return (
      <>
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Add Account
          </Typography>
          <form onSubmit={this.handleSubmit} noValidate>
            <Row>
              <Col md="6" xs="12">
                <TEXTFIELD
                  label="Name"
                  value={name}
                  name={"name"}
                  onChange={this.handleChange}
                  errors={errors}
                />
              </Col>
              <Col md="6" xs="12">
                <TEXTFIELD
                  label="Bank Name"
                  value={bankName}
                  name={"bankName"}
                  onChange={this.handleChange}
                  errors={errors}
                />
              </Col>
              <Col md="6" xs="12">
                <TEXTFIELD
                  label="Account Number"
                  value={accountNumber}
                  type="number"
                  name={"accountNumber"}
                  onChange={this.handleChange}
                  errors={errors}
                />
              </Col>
              <Col md="6" xs="12">
                <TEXTFIELD
                  label="Routing Number"
                  value={routingNumber}
                  name={"routingNumber"}
                  type="number"
                  onChange={this.handleChange}
                  errors={errors}
                />
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md="3" xs="12">
                {/* <SUBMIT title="Submit" disabled={disabled} /> */}
                <div className="go_to_next_btn" onClick={this.handleSubmit}>
                  <Button disabled={disabled}>
                    Save
                    <ArrowForwardIcon
                      fontSize="small"
                      style={{ marginLeft: "10px" }}
                    />
                  </Button>
                </div>
              </Col>
            </Row>
          </form>

          <ToastContainer />
        </div>
        {/* </Grid> */}
        {/* </Grid> */}
      </>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps, {
  hideMessage,
  showAuthLoader,
  userSignInSuccess,
  hideAuthLoader,
  storeRegistrationDetails,
  registrationStep,
})(withStyles(useStyles)(AddBankDetails));
