import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Header from "components/Header/index";
import Sidebar from "containers/SideNav/index";
import Footer from "components/Footer";

import Dashboard from "./routes/dashboard";
import Customers from "./routes/customers";
import Drivers from "./routes/drivers";
import CarTypes from "./routes/car-types";
import Trips from "./routes/trips";
import PromoCodes from "./routes/promo-codes";
import AdminUser from "./routes/users";
import Availability from "./routes/availability";
import AddBankDetails from "../containers/pages/signUp/AddBankDetails";
import AddBankDetails2 from "./routes/addBankDetails";
import ManageCategory from "./routes/manage-category";

import PushNotification from "./routes/notification";
import Orders from "./routes/orders";
import Storeorder from "./routes/storeorders";
import Faq from "./routes/faq";
import RegistrationStep from "./routes/profile";
import ContentPage from "./routes/contentPages";
import Settings from "./routes/settings";
import Pricing from "./routes/pricing";
import Cuisines from "./routes/cuisines";
import Promotions from "./routes/promotions";
import Restaurants from "./routes/vendor";
import Report from "./routes/report";
import Account from "./routes/account";

import StoreTypes from "./routes/store-types";
import Categories from "./routes/categories";
import Products from "./routes/products";
import Subscription from "./routes/subscriptions";
import Subuser from "./routes/subuser"

import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from "constants/ActionTypes";

import { isIOS, isMobile } from "react-device-detect";
import asyncComponent from "../util/asyncComponent";
import TopNav from "components/TopNav";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

class App extends React.Component {
  render() {
    const {
      match,
      drawerType,
      navigationStyle,
      horizontalNavPosition,
    } = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? "fixed-drawer"
      : drawerType.includes(COLLAPSED_DRAWER)
      ? "collapsible-drawer"
      : "mini-drawer";

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add("ios-mobile-view-height");
    } else if (document.body.classList.contains("ios-mobile-view-height")) {
      document.body.classList.remove("ios-mobile-view-height");
    }

    return (
      <div className={`app-container ${drawerStyle}`}>
        <Sidebar />
        <div className="app-main-container">
          <div
            className={`app-header ${
              navigationStyle === HORIZONTAL_NAVIGATION
                ? "app-header-horizontal"
                : ""
            }`}
          >
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === ABOVE_THE_HEADER && (
                <TopNav styleName="app-top-header" />
              )}
            <Header />
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === BELOW_THE_HEADER && <TopNav />}
          </div>

          <main className="app-main-content-wrapper">
            <div className="app-main-content">
              <Switch>
                <Route path={`${match.url}/dashboard`} component={Dashboard} />
                <Route path={`${match.url}/drivers`} component={Drivers} />
                <Route path={`${match.url}/customers`} component={Customers} />
                <Route path={`${match.url}/online-order`} component={Orders} />
                <Route path={`${match.url}/store-order`} component={Storeorder} />
                <Route path={`${match.url}/report`} component={Report} />
                <Route path={`${match.url}/trips`} component={Trips} />
                <Route path={`${match.url}/accounts`} component={Account} />

                {/* <Route
                  path={`${match.url}/add-account`}
                  component={AddBankDetails}
                /> */}
                <Route path={`${match.url}/car-types`} component={CarTypes} />
                <Route
                  path={`${match.url}/promo-codes`}
                  component={PromoCodes}
                />
                 <Route
                  path={`${match.url}/subuser`}
                  component={Subuser}
                />
                <Route
                  path={`${match.url}/profile`}
                  component={RegistrationStep}
                />
                <Route
                  path={`${match.url}/notification`}
                  component={PushNotification}
                />
                <Route
                  path={`${match.url}/manage-category`}
                  component={ManageCategory}
                />

                <Route
                  path={`${match.url}/add-account`}
                  component={AddBankDetails2}
                />
                <Route
                  path={`${match.url}/contentPages`}
                  component={ContentPage}
                />
                <Route path={`${match.url}/faq`} component={Faq} />
                <Route
                  path={`${match.url}/store-types`}
                  component={StoreTypes}
                />
                <Route
                  path={`${match.url}/categories`}
                  component={Categories}
                />
                <Route path={`${match.url}/products`} component={Products} />
                <Route
                  path={`${match.url}/subscription`}
                  component={Subscription}
                />
                <Route
                  path={`${match.url}/availability`}
                  component={Availability}
                />
                <Route path={`${match.url}/pricing`} component={Pricing} />
                <Route path={`${match.url}/cuisines`} component={Cuisines} />
                <Route
                  path={`${match.url}/promotions`}
                  component={Promotions}
                />
                <Route path={`${match.url}/vendor`} component={Restaurants} />
                <Route path={`${match.url}/users`} component={AdminUser} />
                <Route path={`${match.url}/settings`} component={Settings} />
                <Route
                  component={asyncComponent(() =>
                    import("app/routes/extraPages/routes/404")
                  )}
                />
              </Switch>
            </div>
            <Footer />
            <ToastContainer autoClose={2000} />
          </main>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { drawerType, navigationStyle, horizontalNavPosition } = settings;
  return { drawerType, navigationStyle, horizontalNavPosition };
};
export default withRouter(connect(mapStateToProps)(App));
