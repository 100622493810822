import React from "react";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import {
  withRouter,
  connect,
  TEXTFIELD,
  ToastContainer,
  toast,
  Axios,
  PASSWORDFIELD,
  SUBMIT,
  RESET,
  CircularProgress,
  Logo,
  Row,
  Col,
  ApiUrl,
  checkError,
} from "../../components";

import {
  hideMessage,
  showAuthLoader,
  userSignInSuccess,
  hideAuthLoader,
} from "actions/Auth";
const { API_RESET_PASSWORD } = ApiUrl;

const useStyles = (theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(https://source.unsplash.com/random)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      confirmPassword: "",
      disabled: false,
      token: this.props.match.params.token,
      errors: {
        password: "",
        confirmPassword: "",
      },
    };
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    const { errors } = this.state;
    let finalVal = value;
    switch (name) {
      case "password":
        errors.password = value.length < 6 ? "Please Enter 6 Digit " : "";
        if (this.state.confirmPassword !== "") {
          errors.confirmPassword =
            value === this.state.confirmPassword ? "" : "Not Matched";
        }
        break;
      case "confirmPassword":
        errors.confirmPassword =
          value === this.state.password ? "" : "Not Matched";
        errors.confirmPassword =
          value.length < 6 ? "Please Enter 6 Digit " : "";
        break;
      default:
        break;
    }

    // this.setState({ [event.target.name]: event.target.value, disabled });
    this.setState({ errors, [name]: finalVal }, () => {
      let err = checkError(errors);
      this.setState({ disabled: err.disabled });
    });
  };

  resetPassword = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
      disabled: true,
    });

    const { password, token } = this.state;

    let data = {
      newPassword: password,
      token: token,
    };
    Axios.post(API_RESET_PASSWORD, data)
      .then((response) => {
        const { message, status } = response.data;
        if (status === "success") {
          toast.success(message);
          this.setState({ resetForm: false });
          setTimeout(() => {
            this.props.history.push("/signin");
          }, 500);
        } else {
          toast.error(message);
        }

        this.setState({
          loading: false,
          disabled: false,
        });
      })
      .catch((err) => {
        let error = err.response;

        this.setState({
          loading: false,
          disabled: false,
          resetForm: false,
        });
      });
  };

  reset = () => {
    this.setState({
      email: "",
      password: "",
      confirmPassword: "",
      // resetForm: false,
      disabled: false,
      errors: {
        password: "",
        confirmPassword: "",
      },
    });
  };

  render() {
    const {
      email,
      password,
      confirmPassword,
      otp,
      resetForm,
      loading,
      disabled,
      errors,
    } = this.state;
    const { classes } = this.props;
    console.log(this.props.match.params.token, "65564564");
    return (
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={4}
          container
          justify="center"
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          className="login-page-sidebar"
        >
          <img src={require("assets/images/logo.png")} className="logo-login" />
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          md={8}
          component={Paper}
          elevation={6}
          square
          alignContent="center"
          style={{ maxHeight: "100vh", overflow: "scroll" }}
        >
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {"Reset Password"}
            </Typography>
            <Row>
              <Col sm="12" md={{ offset: 2, size: 8 }}>
                <form
                  className={classes.form}
                  noValidate
                  onSubmit={(e) => this.resetPassword(e)}
                >
                  <PASSWORDFIELD
                    label="Password"
                    name="password"
                    onChange={this.handleChange}
                    value={password}
                    errors={errors}
                  />
                  <PASSWORDFIELD
                    label="Confirm Password"
                    name="confirmPassword"
                    onChange={this.handleChange}
                    value={confirmPassword}
                    errors={errors}
                  />

                  <Row>
                    <Col md="5" xs="12">
                      <SUBMIT title="Update" disabled={disabled} />
                    </Col>
                    <Col md="4" xs="12">
                      <RESET onClick={this.reset} />
                    </Col>
                  </Row>
                  <Grid container>
                    <Grid item xs>
                      <Link
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.forgotPassword(e);
                        }}
                        variant="body2"
                      >
                        Resend OTP
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link href="#/signin" variant="body2">
                        {"Go to Login"}
                      </Link>
                    </Grid>
                  </Grid>
                </form>
              </Col>
            </Row>
            <ToastContainer />
          </div>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps, {
  hideMessage,
  showAuthLoader,
  userSignInSuccess,
  hideAuthLoader,
})(withStyles(useStyles)(ResetPassword));
