import React from "react";
import Avatar from "@material-ui/core/Avatar";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { registrationStep } from "../../../actions/Auth";

import {
  withRouter,
  connect,
  TEXTFIELD,
  ToastContainer,
  toast,
  Axios,
  PASSWORDFIELD,
  SUBMIT,
  checkValidations,
  PHONECODES,
  checkError,
  RESET,
  SELECT,
  Button,
  Row,
  Col,
  ApiUrl,
  SEARCHFILTER,
} from "../../../components/index";

const { API_VERIFY_OTP, API_RESEND_OTP } = ApiUrl;

const useStyles = (theme) => ({
  root: {
    height: "100vh",
    backgroundColor: "#F2F7F4",
  },
  image: {
    backgroundImage: "url(https://source.unsplash.com/random)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class OtpVerify extends React.Component {
  constructor(props) {
    super(props);

    console.log(this.props, 121112);
    this.state = {
      otp: "",
      mobileNumber: "",
      countryCode: "",
      disabled: true,
      errors: {
        otp: "",
        mobileNumber: "",
        countryCode: "",
      },
    };
  }
  componentDidMount() {
    const { countryCode, mobileNumber } = this.props;
    this.setState({ countryCode: countryCode, mobileNumber: mobileNumber });
  }
  resend_otp = () => {
    let data = {
      mobileNumber: this.props.mobileNumber,
      countryCode: this.props.countryCode,
    };
    Axios.post(API_RESEND_OTP, data)
      .then((res) => {
        console.log(res.data, "65564");
        if (res.data.status == "success") {
          toast.success(res.data.message);
        } else {
          toast.success(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err, "65564");
      });
  };
  redirect = () => {
    this.props.registrationStep(1);
    this.props.history.push("signin");
  };

  submit = (e) => {
    e.preventDefault();
    const { otp, mobileNumber, countryCode } = this.state;

    let data = {
      otp: otp,
      mobileNumber: mobileNumber,
      countryCode: countryCode,
    };
    Axios.post(API_VERIFY_OTP, data)
      .then((res) => {
        let response = res.data;
        console.log(response);
        if (response.status == "success") {
          setTimeout(() => {
            this.redirect();
          }, 500);
          toast.success(response.message);
          if (response.exist) {
          }
        } else {
          toast.warn(response.message);
        }
      })
      .catch((error) => {
        console.log(error, "data");
      });
  };
  handleChange = (event) => {
    const { name, value } = event.target;
    let finalVal = value;
    let errors = this.state.errors;
    switch (name) {
      case "countryCode":
        errors.countryCode = value.length > 0 ? "" : "Required";
        break;
      case "mobileNumber":
        errors.mobileNumber = value.length > 0 ? "" : "Required";
        break;
      case "otp":
        errors.otp = value.length > 0 ? "" : "Required";
        break;

      default:
        break;
    }

    this.setState({ errors, [name]: finalVal }, () => {
      let err = checkError(errors);
      console.log(checkError, errors, "9899778");
      this.setState({ disabled: err.disabled });
    });
  };
  reset = () => {
    this.setState({
      email: "",
      password: "",
    });
  };

  render() {
    const {
      mobileNumber,
      otp,
      countryCode,
      loading,
      errors,
      disabled,
    } = this.state;
    console.log(this.props && this.props, "65564");
    const { classes } = this.props;

    return (
      <div className={classes.paper}>
        <Grid
          item
          xs={false}
          sm={4}
          md={12}
          container
          justify="center"
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          className="login-page-sidebar"
        >
          <img
            src={require("assets/images/logo.png")}
            className="logo-login"
            style={{
              height: "60px",
              width: "60px",
              objectFit: "contain",
            }}
          />
        </Grid>
        <Typography component="h1" variant="h5">
          Verify OTP
        </Typography>
        <Row className={"justify-content-center"}>
          <Col sm="12" md={"8"}>
            <form
              className={classes.form}
              noValidate
              onSubmit={(e) => this.submit(e)}
            >
              {/* <SELECT
                label="Country Code"
                value={countryCode}
                all={true}
                name="countryCode"
                data={PHONECODES}
                errors={errors}
                onChange={this.handleChange}
              /> */}
              <SEARCHFILTER
                list="countryCode"
                label="Search Country Code"
                value={countryCode}
                name={"countryCode"}
                onChange={this.handleChange}
                errors={errors}
                data={PHONECODES}
              />
              <TEXTFIELD
                label="Mobile Number"
                name="mobileNumber"
                type="number"
                onChange={this.handleChange}
                value={mobileNumber}
                errors={errors}
              />
              <TEXTFIELD
                label="OTP"
                name="otp"
                value={otp}
                onChange={this.handleChange}
                errors={errors}
              />
              {/* <SUBMIT
                  title="Verify"
                  onClick={this.submit}
                  disabled={disabled}
                /> */}
              <Row className="justify-content-center">
                <Col md="6" xs="12">
                  {/* <SUBMIT title="Submit" disabled={disabled} /> */}

                  <div className="go_to_next_btn" onClick={this.submit}>
                    <Button disabled={disabled}>
                      Next
                      <ArrowForwardIcon
                        fontSize="small"
                        style={{ marginLeft: "10px" }}
                      />
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Grid item>
                  <Button onClick={this.resend_otp} variant="outlined">
                    {"Re Send OTP"}
                  </Button>
                </Grid>
              </Row>
            </form>
          </Col>
        </Row>
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { storeRegistraionData } = auth;
  console.log(storeRegistraionData, "68564864");
  const { countryCode, mobileNumber } = storeRegistraionData;
  return {
    countryCode,
    mobileNumber,
  };
};

export default connect(mapStateToProps, { registrationStep })(
  withStyles(useStyles)(withRouter(OtpVerify))
);
