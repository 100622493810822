import React from "react";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import {
  withRouter,
  connect,
  TEXTFIELD,
  ToastContainer,
  toast,
  Axios,
  PASSWORDFIELD,
  SUBMIT,
  RESET,
  CircularProgress,
  Logo,
  Row,
  Col,
  ApiUrl,
} from "../../components";

import {
  hideMessage,
  showAuthLoader,
  userSignInSuccess,
  hideAuthLoader,
} from "actions/Auth";
const { API_FORGOT_PASSWORD, API_RESET_PASSWORD } = ApiUrl;

const useStyles = (theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(https://source.unsplash.com/random)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class SignIn extends React.Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      confirmPassword: "",
      otp: "",
      resetForm: false,
      disabled: false,
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  forgotPassword = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
      disabled: true,
    });

    const { email } = this.state;

    let data = {
      email: email,
      link:
        "https://alcooly-store.ondemandcreations.com/#/signin/#/reset-password/token",
    };
    Axios.post(API_FORGOT_PASSWORD, data)
      .then((response) => {
        const { message, status } = response.data;
        if (status === "success") {
          toast.success("Password Recover Email Sent");
          this.setState({ resetForm: true });
        } else {
          toast.error(message);
        }

        this.setState({
          loading: false,
          disabled: false,
        });
      })
      .catch((err) => {
        let error = err.response;

        this.setState({
          loading: false,
          disabled: false,
          resetForm: false,
        });
      });
  };
  resetPassword = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
      disabled: true,
    });

    const { email } = this.state;
  };
  reset = () => {
    this.setState({
      email: "",
      disabled: false,
    });
  };

  render() {
    const { email, resetForm, loading, disabled } = this.state;
    const { classes } = this.props;

    return (
      <Grid
        container
        component="main"
        className={classes.root}
        style={{ background: "white" }}
      >
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={4}
          container
          justify="center"
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          className="login-page-sidebar"
        >
          <img
            src={require("assets/images/forgot-password.jpg")}
            className="logo-login"
            style={{ width: "300px", height: "400px", objectFit: "contain" }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          md={8}
          component={Paper}
          elevation={6}
          square
          alignContent="center"
          style={{ maxHeight: "100vh", overflow: "scroll" }}
        >
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {"Forgot Password"}
            </Typography>
            <Row>
              <Col sm="12" md={12}>
                <form
                  className={classes.form}
                  noValidate
                  onSubmit={(e) => this.forgotPassword(e)}
                >
                  <TEXTFIELD
                    label="Enter Email"
                    fullWidth
                    name="email"
                    onChange={this.handleChange}
                    value={email}
                    errors={{}}
                  />

                  <SUBMIT title="Submit" disabled={disabled} />
                  <Grid container>
                    <Grid item>
                      <Link href="#/signin" variant="body2">
                        {"Go To Login"}
                      </Link>
                    </Grid>
                  </Grid>
                </form>
              </Col>
            </Row>
            <ToastContainer />
          </div>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps, {
  hideMessage,
  showAuthLoader,
  userSignInSuccess,
  hideAuthLoader,
})(withStyles(useStyles)(SignIn));
