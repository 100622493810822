import React from "react";
import Avatar from "@material-ui/core/Avatar";
import { connect } from "react-redux";
import Menu from "@material-ui/core/Menu";
import { NavLink, withRouter } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import { userSignOut } from "../../actions/Auth";
import IntlMessages from "util/IntlMessages";
import Chip from "@material-ui/core/Chip";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { toast } from "react-toastify";

class UserInfo extends React.Component {
  state = {
    anchorEl: null,
    open: false,
  };

  handleClick = (event) => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };
  logOut = () => {
    toast.success("LogOut Successfully");
    this.props.history.push("/signin");
    setTimeout(() => {
      localStorage.clear();
      window.location.reload(false);
    }, 500);
  };

  render() {
    console.log(this.props.data, "564546546546");
    return (
      <div className="user-profile d-flex flex-row align-items-center">
        {/* <p> */}
        <h4 style={{ marginTop: "10px" }}>
          {Notification.permission !== "granted" ? (
            <span
              style={{
                color: "#fff",
                padding: " 22px",
                marginTop: "16px",
                fontWeight: "bold",
              }}
            >
              Notification Blocked{" "}
              <i
                class="zmdi zmdi-notifications-off"
                style={{ marginLeft: "6px" }}
              ></i>
            </span>
          ) : (
            <span
              style={{
                color: "#fff",
                padding: " 22px",
                marginTop: "16px",
                fontWeight: "bold",
              }}
            >
              Notification Allowed
              <i
                class="zmdi zmdi-notifications-active"
                style={{ marginLeft: "6px" }}
              ></i>
            </span>
          )}
        </h4>
        {/* </p> */}
        {/* <div
          className="user-detail notificationicon"
          onClick={this.handleClick2}
        >
          <i class="zmdi zmdi-notifications-active"></i>
          <span className="not-count ">{5}</span>
        </div>{" "} */}
        <div className="user-detail" onClick={this.handleClick}>
          <Chip
            icon={<AccountCircleIcon />}
            label={localStorage.getItem("name")}
          />
          <i class="zmdi zmdi-caret-down zmdi-hc-fw align-middle"></i>
        </div>
        <Menu
          className="user-info"
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onClose={this.handleRequestClose}
          PaperProps={{
            style: {
              minWidth: 120,
              paddingTop: 0,
              paddingBottom: 0,
            },
          }}
        >
          <MenuItem
            onClick={() => {
              this.handleRequestClose();
            }}
          >
            <NavLink to="/app/settings/password-change">
              <i class="zmdi zmdi-key zmdi-hc-fw mr-2"></i>
              <IntlMessages id="Password" />
            </NavLink>
          </MenuItem>
          <MenuItem
            onClick={() =>
              // this.handleRequestClose();
              // this.props.userSignOut();
              this.logOut()
            }
          >
            <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />

            <IntlMessages id="popup.logout" />
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { locale } = settings;
  return { locale };
};
export default connect(mapStateToProps, { userSignOut })(withRouter(UserInfo));
