import React, { useEffect, useRef, useState } from "react";
import {
  Axios,
  ApiUrl,
  toast,
  TextField,
  TEXTFIELD,
  Button,
  Row,
  Col,
  moment,
  ToastContainer,
  NavLinkButton,
  connect,
  withRouter,
} from "../../../components";
import {
  storeRegistrationDetails,
  registrationStep,
} from "../../../actions/Auth";
import InputMask from "react-input-mask";
import Loader from "../../../components/Loader";
const { API_GET_SUBSCRIPTION_LIST, API_ADD_CARD, API_STOR_SUBSCRIBE } = ApiUrl;
const subscriptionList = (props) => {
  const [state, setState] = useState([]);
  const [subscriptionId, setsubscriptionId] = useState();
  const [activeIndex, setActiveIndex] = useState(null);
  const [cardDetails, setCardDetails] = useState({
    cardNumber: null,
    expirationDate: null,
    email: "",
    cvv: "",
  });
  const [loader, setLoader] = useState(false);
  const [hideShowEle, setHideShowEle] = useState(true);
  useEffect(() => {
    Axios.get(API_GET_SUBSCRIPTION_LIST)
      .then((res) => {
        let response = res.data.data;
        console.log(res.data.data, "098908");
        setState(response);
      })
      .catch((error) => {
        toast.error("Error");
        console.log(error, ":error");
      });
  }, []);
  useEffect(() => {
    setCardDetails({ email: props.storeRegistraionData.email || "" });
  }, []);
  const getSubscriptionPackage = (id, activeIndex) => {
    setsubscriptionId(id);
    setActiveIndex(activeIndex);
  };
  const changeHadler = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setCardDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { cardNumber, expirationDate, email, cvv } = cardDetails;

    let data = {
      cardNumber: cardNumber.replace(/\D/g, ""),
      expirationDate: expirationDate,
      email: email,
      cardCode: cvv,
    };
    setLoader(true);
    console.log(data, "98888888888");
    Axios.post(API_ADD_CARD, data)
      .then((res) => {
        let repsonse = res.data;
        if (repsonse.status == "success") {
          toast.success(repsonse.message);
          setLoader(false);
          setHideShowEle(false);
          props.storeRegistrationDetails(repsonse.data);

          props.callback(repsonse.data);
        } else {
          setLoader(false);
          toast.error(repsonse.message);
        }
      })
      .catch((errr) => {
        // toast.error("Error");
        setLoader(false);
      });
  };
  const redirect = () => {
    props.history.push("signin");
    props.registrationStep(1);
  };
  useEffect(() => {}, []);
  const complete_registration = () => {
    let data = {
      subscriptionId: subscriptionId,
      paymentRefId:
        (props.storeRegistraionData && props.storeRegistraionData._id) || "",
      email: cardDetails.email,
    };
    setLoader(true);
    Axios.post(API_STOR_SUBSCRIBE, data)
      .then((res) => {
        let data = res.data;
        if (data.status == "success") {
          toast.success(data.message);
          setLoader(false);
          setTimeout(() => {
            redirect();
          }, 100);
        } else {
          toast.error(data.message);
          setLoader(false);
        }
      })
      .catch((errr) => {
        toast.error("Error");
        setLoader(false);
      });
  };
  console.log(hideShowEle, "prev");
  const { expirationDate, cardNumber, email, cvv } = cardDetails;

  return (
    <div className="subcription_container">
      <h2 className="subscription_text">Select Subscription</h2>
      <div className="subscription_package_main">
        {loader && <Loader />}

        {state.length > 0 &&
          state.map((dkey, i) => {
            return (
              <div
                className={
                  activeIndex == i
                    ? "subscription_package_box active_package "
                    : "subscription_package_box"
                }
                key={i}
                onClick={() => getSubscriptionPackage(dkey._id, i)}
              >
                <h4 className="text-capitalize">{dkey.name}</h4>
                <div className="subscription_package_inner">
                  <p>
                    <span className="month_text">{dkey.month}</span> months
                  </p>
                  <p>
                    $ <span className="price_text">{dkey.price}</span>{" "}
                  </p>
                </div>
                <label htmlFor={dkey._id}>Select Package</label>
                <input type="radio" id={dkey._id} />
              </div>
            );
          })}
      </div>
      {console.log(props, "545456")}
      <div className="add_card">
        {props.storeRegistraionData.paymentId &&
          props.storeRegistraionData.paymentId && (
            <>
              <h1>Card Added</h1>{" "}
              <p> Name :{props.storeRegistraionData.name || "N/A"}</p>
              <p> Type: {props.storeRegistraionData.type || "po"}</p>
              <button
                onClick={complete_registration}
                className="complete_payment_btn"
                size="large"
                variant="contained"
                color="primary"
              >
                Complete Payment
              </button>
            </>
          )}
        {!props.storeRegistraionData.paymentId && (
          <>
            <h3>Add Card</h3>
            <div className="add_card_form">
              <Row className=" ">
                <Col md="4">
                  <InputMask
                    mask="9999 9999 9999 9999"
                    value={cardNumber}
                    disabled={false}
                    onChange={changeHadler}
                    maskChar=" "
                  >
                    {() => (
                      <input placeholder="Card Number" name="cardNumber" />
                    )}
                  </InputMask>
                </Col>
                <Col md="4">
                  <InputMask
                    mask="9999-99"
                    value={expirationDate}
                    onChange={changeHadler}
                    maskChar=" "
                  >
                    {() => (
                      <input
                        placeholder="Expire Date YYYY/MM"
                        name="expirationDate"
                      />
                    )}
                  </InputMask>
                </Col>
                <Col md="4">
                  <input
                    type="text"
                    name="cvv"
                    value={cvv}
                    onChange={changeHadler}
                    placeholder="CVV"
                  />
                </Col>
                <Col md="4" className="pt-4">
                  <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={changeHadler}
                    placeholder="email"
                  />
                </Col>
                <Col md="12" className="py-3">
                  <button
                    onClick={handleSubmit}
                    className="_add_card_btn"
                    size="large"
                    variant="contained"
                    color="primary"
                  >
                    Add
                  </button>
                </Col>
              </Row>
            </div>
          </>
        )}
        <ToastContainer />
      </div>
    </div>
  );
};
const mapStateToProps = ({ auth }) => {
  const { storeRegistraionData } = auth;
  return {
    storeRegistraionData,
  };
};
const mapActionToProps = { storeRegistrationDetails, registrationStep };
export default connect(
  mapStateToProps,
  mapActionToProps
)(withRouter(subscriptionList));
