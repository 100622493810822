import React, { useState } from "react";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { Route, Switch, HashRouter, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { onMessageListener } from "./firebaseInit";
import Notifications from "./components/Notifications/Notifications";
import store, { history } from "./store";
import ReactNotificationComponent from "./components/Notifications/ReactNotification";

import App from "./containers/App";
import { useEffect } from "react";
import useSocket from "./useSocket";

const {
  Provider: SocketProvider,
  Consumer: SocketConsumer,
} = React.createContext();

const MainApp = () => {
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState([]);
  const [currentnotification, setcurrentNotification] = useState({
    title: "",
    body: "",
  });

  const SaveNotifcation = (payload) => {
    setNotification((pre) => [...pre, payload]);
  };

  const reloadFun = () => {
    window.location.reload();
  };

  onMessageListener()
    .then((payload) => {
      setShow(true);
      setcurrentNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
      if (payload) {
        setTimeout(() => {
          reloadFun();
        }, 2000);
      }

      SaveNotifcation(payload);
    })
    .catch((err) => console.log("failed: ", err));

  useEffect(() => {
    if (currentnotification.title !== "") {
      toast.info(<Toast />);
    }
  }, [currentnotification]);
  function Toast() {
    return (
      <>
        <h4>{currentnotification.title}</h4>
        <p>{currentnotification.body}</p>
      </>
    );
  }
  return (
    <Provider store={store}>
      <Notifications />
      <ConnectedRouter history={history}>
        <Switch>
          <HashRouter>
            <Route path="/" component={App} />
          </HashRouter>
        </Switch>
      </ConnectedRouter>
      {/* </Provider> */}
    </Provider>
  );
};
export { SocketConsumer };
export default MainApp;
