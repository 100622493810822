import React from "react";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import OtpVerify from "./otpVerify";
import { withStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import {
  withRouter,
  connect,
  TEXTFIELD,
  Button,
  ToastContainer,
  toast,
  Axios,
  PASSWORDFIELD,
  SUBMIT,
  RESET,
  SELECT,
  GoogleAutocomplete,
  CircularProgress,
  Logo,
  checkValidations,
  checkError,
  FILEUPLOAD,
  Row,
  Col,
  ApiUrl,
  Input,
  InputLabel,
  MenuItem,
  // FormControl,
  SEARCHFILTER,
  Select,
  COUNTRIES,
  PHONECODES,
} from "../../../components";

import {
  hideMessage,
  showAuthLoader,
  userSignInSuccess,
  hideAuthLoader,
} from "actions/Auth";
import {
  storeRegistrationDetails,
  registrationStep,
} from "../../../actions/Auth";

const {
  EMAIL_REGEX,
  PASS_REGEX,
  PASS_VALIDATION_MSG,
  Numeric,
  API_ADD_STORES,
  API_GET_DEALS_URL,
  API_GET_ALL_STORES_TYPES,
  API_EDIT_STORES,
  API_GET_ALL_CATEGORY_URL,
  API_GET_CUISINES_URL,
  STORE_TERM_CONDITIONS,
  PRIVACY_POLICY,
  API_URL,
} = ApiUrl;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const useStyles = (theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(https://source.unsplash.com/random)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

// $("input,textarea").val("");
// $(".form-group input, .form-group textarea").focusout(function() {
//   var text_val = $(this).val();
//   if (text_val === "") {
//     console.log("empty!");
//     $(this).removeClass("has-value");
//   } else {
//     $(this).addClass("has-value");
//   }
// });

// const GreenRadio = withStyles({
//   root: {
//     color: green[400],
//     "&$checked": {
//       color: green[600],
//     },
//   },
//   checked: {},
// })((props) => <Radio color="default" {...props} />);

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editId: this.props.editId,
      process: this.props.role,
      imagePreviewUrl: "",
      details: this.props.details,
      loading: false,
      categoriesList: [],
      cuisinesList: [],
      dealsList: [],
      storeList: [],
      storeType: "",
      firstName: "",
      lastName: "",
      name: "",
      litterTax: "yes",
      bottlesDeposit: "yes",
      giftPrize: "",
      profileImage: "",
      storeLogo: "",
      personalizedPrize: "",
      isGiftPrize: "yes",
      isPersonalizedPrize: true,
      companyName: "",
      storeOwnerMobileNumber: "",
      state: "",
      storeDeliveryType: "",
      storeOwnerName: "",
      dliveryType: [],
      email: "",
      serviceList: [],
      country: "",
      countryCode: "+1",
      mobileNumber: "",
      city: "",
      postCode: "",
      address: "",
      lng: "",
      lat: "",
      nif: "",
      categories: [],
      cuisines: [],
      deals: [],
      businessType: [],
      selectedOption: [],
      EIN: "",
      // images: {},
      businessproof: {},
      password: "",
      password2: "",
      imagePreviewUrl: "",
      isAgree: false,
      userType: 0,
      stepPages: {
        registerPage: true,
        otpPage: false,
        subScriptionPage: false,
      },
      errors: {
        name: "",
        email: "",
        personalizedPrize: "",
        isGiftPrize: "",
        isPersonalizedPrize: "",
        state: "",
        companyName: "",
        storeOwnerMobileNumber: "",
        storeOwnerName: "",
        country: "",
        countryCode: "",
        EIN: "",
        city: "",
        address: "",
        lng: "",
        lat: "",
        profileImage: "",
        // storeLogo: "",
        // businessproof: this.props.role !== "edit" ? "Required" : "",
      },
      disabled: true,
    };
  }

  componentDidMount() {}

  handleChange = (event) => {
    event.preventDefault();
    const { name, value, validations } = event.target;
    let finalVal = value;
    let errors = this.state.errors;
    switch (name) {
      case "name":
        errors.name = value.length < 1 ? "Required" : "";
        break;
      case "vendorName":
        errors.vendorName = value.length < 1 ? "Required" : "";
        break;
      case "email": {
        let err = "";
        if (value.length < 1) {
          err = "Required";
        } else {
          if (!EMAIL_REGEX.test(value)) {
            err = "Invalid email";
          }
        }
        errors.email = err;
        break;
      }
      case "password": {
        let err = "";
        if (value.length < 1) {
          err = "Required";
        } else {
          if (!PASS_REGEX.test(value)) {
            err = "Invalid Password";
          }
        }
        errors.password = err;
        break;
      }
      case "password2": {
        let err = "";
        if (value.length < 0) {
          err = "Required";
        } else {
          if (this.state.password !== value) {
            err = "Confirm Password should be match to Password";
          }
        }
        errors.password2 = err;
        break;
      }
      case "mobileNumber": {
        let err = "";
        if (value.length < 10) {
          err = "10 digits are required";
        } else if (value.length > 10) {
          err = "More than 10 digits are not allowed";
        } else {
          if (!Numeric.test(value)) {
            err = "Only numeric allowed";
            finalVal = "";
          }
        }
        errors.mobileNumber = err;

        break;
      }

      case "country":
        errors.country = value.length < 1 ? "Required" : "";
        break;
      case "countryCode":
        errors.countryCode = value.length < 1 ? "Required" : "";
        break;

      case "city":
        errors.city = value.length < 1 ? "Required" : "";
        break;
      case "address":
        errors.address = value.length < 1 ? "Required" : "";
        break;
      case "categories":
        errors.categories = value.length < 1 ? "Required" : "";
        break;
      case "EIN":
        errors.EIN = value.length < 1 ? "Required" : "";
        break;
      case "companyName":
        errors.companyName = value.length < 1 ? "Required" : "";
        break;
      case "storeOwnerMobileNumber":
        let err = "";
        if (value.length < 10) {
          err = "10 digits are required";
        } else if (value.length > 10) {
          err = "More than 10 digits are not allowed";
        } else {
          if (!Numeric.test(value)) {
            err = "Only numeric allowed";
            finalVal = "";
          }
        }
        errors.storeOwnerMobileNumber = err;

        // errors.storeOwnerMobileNumber = value.length < 1 ? "Required" : "";
        break;
      case "storeType":
        errors.storeType = value.length < 1 ? "Required" : "";
        break;
      default:
        errors[name] = value.length < 1 ? "Required" : "";
        break;
    }

    this.setState({ errors, [name]: finalVal }, () => {
      let err = checkError(errors);
      console.log(checkError, errors, "9899778");
      this.setState({ disabled: err.disabled });
    });
  };

  handleFile = (event) => {
    event.preventDefault();
    const { errors } = this.state;
    let image = event.target.files[0];
    let newErr = { ...errors, [event.target.name]: "" };
    let reader = new FileReader();
    this.setState(
      {
        [event.target.name]: image,
        errors: newErr,
      },
      () => {
        let err = checkError(newErr);
        this.setState({ disabled: err.disabled });
      }
    );
  };
  redirectPage = () => {
    // this.props.history.push("/otp-verify");
    this.props.registrationStep(2);
  };
  handleAddress = (address) => {
    let errors = this.state.errors;
    let add = address;
    errors.address = address.address < 1 ? "Required" : "";
    add.errors = errors;
    this.setState(add);
    console.log(address, "554");
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ loading: true, disabled: true });
    const {
      name,
      email,
      mobileNumber,
      country,
      countryCode,
      city,
      address,
      postCode,
      storeLogo,
      lng,
      lat,
      cuisines,
      firstName,
      lastName,
      giftPrize,
      isGiftPrize,
      businessType,
      categories,
      deals,
      nif,
      images,
      businessproof,
      storeOwnerName,
      password,
      password2,
      isPersonalizedPrize,
      storeOwnerMobileNumber,
      EIN,
      companyName,
      userType,
      isAgree,
      storeDeliveryType,
      storeType,
      state,
      editId,
      profileImage,
      errors,
      process,
      litterTax,
      bottlesDeposit,
    } = this.state;
    console.log(categories, categories.join(","), "catergories");
    let data = {
      name: name,
      email: email,
      firstName: firstName,
      lastName: lastName,
      storeDeliveryType: "Both",
      storeOwnerName: storeOwnerName,
      isGiftPrize: isGiftPrize == "yes" ? true : false,
      litterTax: litterTax == "yes" ? true : false,
      bottlesDeposit: bottlesDeposit == "yes" ? true : false,
      mobileNumber: mobileNumber,
      isPersonalizedPrize: isPersonalizedPrize == "yes" ? true : false,
      address: address,
      city: city,
      country: country,
      state: state,
      companyName: companyName,
      storeOwnerMobileNumber: storeOwnerMobileNumber,
      EIN: EIN,
      countryCode: countryCode,
      lat: lat,
      lng: lng,
      password: password,
      storeType: storeType,
    };
    // if (profileImage.name === undefined) {
    //   errors.profileImage = "Required";
    //   this.setState({ errors });
    //   toast.error("Please Upload Profile Image");
    //   return;
    // }
    // if (storeLogo.name === undefined) {
    //   errors.storeLogo = "Required";
    //   this.setState({ errors });
    //   toast.error("Please Upload Logo");
    //   return;
    // } 
    if (isGiftPrize == "yes") {
      data.giftPrize = giftPrize;
    }
    if (litterTax == "yes") {
      data.litterTax = litterTax;
    }
    if (bottlesDeposit == "yes") {
      data.bottlesDeposit = bottlesDeposit;
    }
    console.log(data, "9879");
    let valid = checkValidations(data);
    if (valid.error) {
      toast.error(valid.error);
      this.setState({ loading: false, disabled: false });
      return false;
    }

    data = valid.data;

    if (profileImage.name !== undefined > 0) {
      data.profileImage = profileImage;
    }
    if (storeLogo.name !== undefined > 0) {
      data.storeLogo = storeLogo;
    }

    let url = API_ADD_STORES;
    if (process == "edit") {
      data.storeid = editId;
      url = API_EDIT_STORES;
    }

    console.log(data, valid, 566565);
    let formData = new FormData();
    for (var key in data) {
      formData.append(key, data[key]);
    }

    Axios.post(url, formData)
      .then((res) => {
        let response = res.data;
        console.log(res, 65456456564);
        const { status, message } = response;
        toast.error(message);
        console.log(status);
        if (status === "failure") {
          // if (status === "failure") {
          this.setState({ loading: false, disabled: false });
        } else {
          toast.success(response.message);
          setTimeout(() => {
            this.redirectPage();
          }, 100);
          this.props.storeRegistrationDetails({
            countryCode: countryCode,
            mobileNumber: mobileNumber,
            email: email,
          });
        }
      })
      .catch((error) => {
        let res = error.response;
        toast.error("Error");
        this.setState({ loading: false, disabled: false });
      });
  };
  reset = () => {
    this.setState({
      imagePreviewUrl: "",
      name: "",
      vendorName: "",
      storeDeliveryType: [],
      email: "",
      country: "",
      countryCode: "",
      mobileNumber: "",
      city: "",
      address: "",
      lng: "",
      lat: "",
      postCode: "",
      userType: "",
      password: "",
      password2: "",
      companyName: "",
      storeOwnerMobileNumber: "",
      storeType: "",
      cuisines: [],
      categories: [],
      businessType: [],
      images: {},
      storeLogo: {},
      errors: {
        name: "",
        vendorName: "",
        storeDeliveryType: "",
        email: "",
        country: "",
        countryCode: "",
        address: "",
        lng: "",
        lat: "",
        cuisines: "",
        categories: "",
        storeType: "",
        postCode: "",
        userType: "",
        password: "",
        password2: "",
        profileImage: "",
      },
      disabled: true,
    });
  };
  showChecked = (key, value, e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  render() {
    const {
      categoriesList,
      cuisinesList,
      storeDeliveryType,
      dealsList,
      EIN,
      firstName,
      lastName,
      giftPrize,
      isGiftPrize,
      storeType,
      personalizedPrize,
      storeLogo,
      companyName,
      storeOwnerMobileNumber,
      profileImage,
      storeOwnerName,
      state,
      storeList,
      name,
      email,
      imagePreviewUrl,
      mobileNumber,
      country,
      countryCode,
      city,
      postCode,
      nif,
      address,
      lng,
      lat,
      password,
      password2,
      userType,
      serviceList,
      cuisines,
      categories,
      deals,
      businessType,
      images,
      selectedOption,
      isPersonalizedPrize,
      isAgree,
      errors,
      disabled,
      loading,
      yes,
      no,
      litterTax,
      bottlesDeposit,
    } = this.state;
    const { registerPage, otpPage, subScriptionPage } = this.state.stepPages;
    const { classes } = this.props;
    console.log(this.props, "serviceList");

    return (
      <>
        {/* <Grid container component="main" className={classes.root}>
          <Grid
            item
            xs={false}
            sm={4}
            md={4}
            container
            justify="center"
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            className="login-page-sidebar"
          >
            <img
              src={require("assets/images/logInBanner.jpg")}
              className="logo-login"
              style={{ height: "100%", width: "500px", objectFit: "cover" }}
            />
          </Grid> */}
        {/*
        <Grid
          item
          xs={12}
          sm={8}
          md={8}
          component={Paper}
          elevation={6}
          square
          alignContent="center"
          style={{ maxHeight: "100vh", overflow: "scroll" }}
        > */}
        <div className={classes.paper}>
          <Grid
            item
            xs={false}
            sm={4}
            md={12}
            container
            justify="center"
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            className="login-page-sidebar"
          >
            <img
              src={require("assets/images/logo.png")}
              className="logo-login"
              style={{
                height: "60px",
                width: "60px",
                objectFit: "contain",
              }}
            />
          </Grid>
          <Typography component="h1" variant="h5">
            Sign Up
          </Typography>

          <form onSubmit={this.handleSubmit} noValidate>
            <Row>
              <Col md="12" xs="12">
                <Row>
                  <Col md="4" xs="12">
                    <TEXTFIELD
                      label="First Name "
                      required
                      value={firstName}
                      name={"firstName"}
                      onChange={this.handleChange}
                      errors={errors}
                    />
                  </Col>
                  <Col md="4" xs="12">
                    <TEXTFIELD
                      label="Last Name "
                      required
                      value={lastName}
                      name={"lastName"}
                      onChange={this.handleChange}
                      errors={errors}
                    />
                  </Col>
                  <Col md="4" xs="12">
                    <TEXTFIELD
                      label="Company Name "
                      required
                      value={companyName}
                      name={"companyName"}
                      onChange={this.handleChange}
                      errors={errors}
                    />
                  </Col>
                  <Col md="4" xs="12">
                    <TEXTFIELD
                      label="Store Name "
                      required
                      value={name}
                      name={"name"}
                      onChange={this.handleChange}
                      errors={errors}
                    />
                  </Col>
                  <Col md="4" xs="12">
                    <TEXTFIELD
                      label="EIN Number "
                      required
                      value={EIN}
                      name={"EIN"}
                      type="number"
                      onChange={this.handleChange}
                      errors={errors}
                    />
                  </Col>
                  <Col md="4" xs="12">
                    <TEXTFIELD
                      label="Store Owner Name "
                      required
                      value={storeOwnerName}
                      name={"storeOwnerName"}
                      onChange={this.handleChange}
                      errors={errors}
                    />
                  </Col>
                  <Col md="4" xs="12">
                    <TEXTFIELD
                      label="Store Owner Mobile "
                      required
                      value={storeOwnerMobileNumber}
                      // type="number"
                      name={"storeOwnerMobileNumber"}
                      onChange={this.handleChange}
                      errors={errors}
                    />
                  </Col>
                  <Col md="4" xs="12">
                    <TEXTFIELD
                      label="Store Mobile Number "
                      required
                      value={mobileNumber}
                      name={"mobileNumber"}
                      // type="number"
                      onChange={this.handleChange}
                      errors={errors}
                    />
                  </Col>
                  <Col md="4" xs="12">
                    <TEXTFIELD
                      label="Email "
                      required
                      value={email}
                      name={"email"}
                      onChange={this.handleChange}
                      errors={errors}
                    />
                  </Col>
                  <Col md="4" xs="12">
                    {/* <SELECT
                      label="Country Code"
                      value={countryCode}
                      name={"countryCode"}
                      onChange={this.handleChange}
                      errors={errors}
                      data={PHONECODES}
                    /> */}

                    <SEARCHFILTER
                      list="countryCode"
                      label="Search Country Code"
                      value={countryCode}
                      name={"countryCode"}
                      onChange={this.handleChange}
                      errors={errors}
                      data={PHONECODES}
                    />
                  </Col>

                  <Col md="4" xs="12">
                    <GoogleAutocomplete
                      label="Store Address *"
                      required
                      address={address}
                      lat={lat}
                      lng={lng}
                      errors={errors}
                      name="address"
                      onChange={this.handleAddress}
                    />
                  </Col>
                  <Col md="4" xs="12">
                    <TEXTFIELD
                      label="City "
                      required
                      value={city}
                      name={"city"}
                      onChange={this.handleChange}
                      errors={errors}
                    />
                  </Col>
                  <Col md="4" xs="12">
                    <TEXTFIELD
                      label="State"
                      value={state}
                      name={"state"}
                      onChange={this.handleChange}
                      errors={errors}
                    />
                  </Col>

                  <Col md="4" xs="12">
                    <SELECT
                      label="Country "
                      required
                      value={country}
                      name={"country"}
                      onChange={this.handleChange}
                      data={COUNTRIES}
                      errors={errors}
                    />
                  </Col>

                  <Col md="4" xs="12">
                    <SELECT
                      label="Store Type *"
                      required
                      value={storeType}
                      name={"storeType"}
                      onChange={this.handleChange}
                      data={[
                        { Liquor_Store: "Liquor Store" },
                        { Convienience_Store: "Convienience Store" },
                        { Both: "Both" },
                      ]}
                    />
                  </Col>

                  <Col md="4" xs="12">
                    <SELECT
                      label="Personalized Prize "
                      required
                      value={isPersonalizedPrize}
                      name={"isPersonalizedPrize"}
                      onChange={this.handleChange}
                      data={[{ yes: "Yes" }, { no: "No" }]}
                      errors={errors}
                    />
                  </Col>
                  <Col md="4" xs="12">
                    <SELECT
                      label="Gift Prize "
                      value={isGiftPrize}
                      name={"isGiftPrize"}
                      onChange={this.handleChange}
                      data={[{ yes: "Yes" }, { no: "No" }]}
                      errors={errors}
                    />
                  </Col>
                  {isGiftPrize == "yes" && (
                    <Col md="4" xs="12">
                      <TEXTFIELD
                        label="Gift Amount"
                        value={giftPrize}
                        type="number"
                        name={"giftPrize"}
                        onChange={this.handleChange}
                        errors={errors}
                      />
                    </Col>
                  )}
                  {/* <Col md="4" xs="12">
                    <SELECT
                      label="Delivery Type"
                      value={storeDeliveryType}
                      name={"storeDeliveryType"}
                      multiple
                      onChange={this.handleChange}
                      data={[
                        { pickup: "Pick Up" },
                        { delivery: "Delivery" },
                        { Both: "Both" },
                      ]}
                      errors={errors}
                    />
                  </Col> */}
                  <Col md="4" xs="12">
                    <PASSWORDFIELD
                      label="Password "
                      required
                      value={password}
                      name={"password"}
                      onChange={this.handleChange}
                      note={PASS_VALIDATION_MSG}
                      errors={errors}
                    />
                  </Col>
                  <Col md="4" xs="12">
                    <PASSWORDFIELD
                      label="Confirm Password "
                      required
                      value={password2}
                      name={"password2"}
                      onChange={this.handleChange}
                      note={PASS_VALIDATION_MSG}
                      errors={errors}
                    />
                  </Col>
                  <Col md="4" xs="12">
                    <FILEUPLOAD
                      label="Profile Image"
                      value={profileImage}
                      name={"profileImage"}
                      onChange={this.handleFile}
                    />
                  </Col>
                  <Col md="4" xs="12">
                    <FILEUPLOAD
                      label="Store Logo"
                      value={storeLogo}
                      name={"storeLogo"}
                      onChange={this.handleFile}
                      errors={errors}
                    />
                  </Col>
                  <Col md="12" xs="12">
                    <FormControl component="fieldset">
                      <FormLabel
                        component="legend"
                        style={{
                          color: "#00000",
                          // fontWeight: "bold"
                        }}
                      >
                        Do you have litter tax ?
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-label="gender"
                        name="row-radio-buttons-group"
                      >
                        <FormControlLabel
                          checked={litterTax === "yes"}
                          value="yes"
                          name="litterTax"
                          control={<Radio />}
                          inputProps={{ "aria-label": "yes" }}
                          label="Yes"
                          onChange={(e) =>
                            this.showChecked("litterTax", "yes", e)
                          }
                        />
                        <FormControlLabel
                          checked={litterTax === "no"}
                          name="litterTax"
                          value="no"
                          control={<Radio />}
                          label="No"
                          inputProps={{ "aria-label": "no" }}
                          onChange={(e) =>
                            this.showChecked("litterTax", "no", e)
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </Col>
                  <Col md="12" xs="12">
                    <FormControl component="fieldset">
                      <FormLabel
                        component="legend"
                        style={{
                          color: "#00000",
                          // fontWeight: "bold"
                        }}
                      >
                        Do you have bottle deposit in your state ?
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-label="gender"
                        name="row-radio-buttons-group"
                      >
                        <FormControlLabel
                          checked={bottlesDeposit === "yes"}
                          value="yes"
                          name="bottlesDeposit"
                          control={<Radio />}
                          inputProps={{ "aria-label": "yes" }}
                          label="Yes"
                          onChange={(e) =>
                            this.showChecked("bottlesDeposit", "yes", e)
                          }
                        />
                        <FormControlLabel
                          checked={bottlesDeposit === "no"}
                          name="bottlesDeposit"
                          value="no"
                          control={<Radio />}
                          label="No"
                          inputProps={{ "aria-label": "no" }}
                          onChange={(e) =>
                            this.showChecked("bottlesDeposit", "no", e)
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </Col>

                  <Col md="12" xs="12">
                    <p style={{ marginTop: 20 }}>
                      By clicking “Submit,” you agree to our{" "}
                      <a
                        href={`${API_URL}/${STORE_TERM_CONDITIONS}`}
                        target="_blank"
                      >
                        Term and Conditions
                      </a>{" "}
                      and acknowledge you have read the{" "}
                      <a href={`${API_URL}/${PRIVACY_POLICY}`} target="_blank">
                        Privacy Policy
                      </a>
                    </p>
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col md="3" xs="12">
                    {/* <SUBMIT title="Submit" disabled={disabled} /> */}
                    <div className="go_to_next_btn" onClick={this.handleSubmit}>
                      <Button disabled={disabled}>
                        Next
                        <ArrowForwardIcon
                          fontSize="small"
                          style={{ marginLeft: "10px" }}
                        />
                      </Button>
                    </div>
                  </Col>
                </Row>
                <Grid container className="justify-content-center">
                  <Grid item>
                    <Link href="#/signin" variant="body2">
                      {"Already have an account ? Sign In"}
                    </Link>
                  </Grid>
                </Grid>
              </Col>
            </Row>
          </form>

          <ToastContainer />
        </div>
        {/* </Grid> */}
        {/* </Grid> */}
      </>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps, {
  hideMessage,
  showAuthLoader,
  userSignInSuccess,
  hideAuthLoader,
  storeRegistrationDetails,
  registrationStep,
})(withStyles(useStyles)(SignUp));
