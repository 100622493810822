// export const checkValidations = state => {
//   let newState = {};
//   let noTrim = ["undefined", "object", "boolean","number"];
//   let error = false;
//   for (let [key, value] of Object.entries(state)) {
//     if (!noTrim.includes(typeof value)) {
//       console.log(key, value);
//       value = value.trim();
//         if (value === "") {
//           key = key.charAt(0).toUpperCase() + key.slice(1);
//           error =true;
//         }
//       newState[key] = value;
//     } else {
//       newState[key] = value;
//     }
//   }
//   if (error) {
//     error = error;
//     console.log(error,656565)
//     // error= "All fields are Required";
//   } else {
//     error = false;
//   }
//   return { data: newState, error: error };
// };

// export const checkError = errors => {
//   let err = "";
//   let disabled = false;
//   for (var key of Object.keys(errors)) {
//     if (errors[key].length > 0) {
//       console.log(key, errors[key], 333);
      
//       err +=  errors[key];
//       // err += "All fields are Required";
//       disabled = true;
//     }
//   }
 
//   return { error: err, disabled };
// };

export const checkValidations = state => {
  let newState = {};
  let noTrim = ["undefined", "object", "boolean","number"];
  let error = "";
  for (let [key, value] of Object.entries(state)) {
    if (!noTrim.includes(typeof value)) {
      value = value.trim();
        if (value === "") {
          key = key.charAt(0).toUpperCase() + key.slice(1);
          error += `${key},`;
        }
      newState[key] = value;
    } else {
      newState[key] = value;
    }
  }
  if (error !== "") {
    error = error.substring(0, error.length - 1);
    error += " is required!";
  } else {
    error = false;
  }
  return { data: newState, error: error };
};

export const checkError = errors => {
  let err = "<ul>";
  let disabled = false;
  for (var key of Object.keys(errors)) {
    if (errors[key].length > 0) {
      err += `<li>${key} ${errors[key]}<li>`;
      disabled = true;
    }
  }
  err += "</ul>";
  return { error: err, disabled };
};


export const CURRENCY_AMOUNT=(value)=>{
  return `$ ${Number(value || 0).toFixed(2)}`
}