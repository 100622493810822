import React, { useEffect, useState } from "react";
import socketIO from "socket.io-client";
import { API_URL } from "./ApiUrl";

function useSocket() {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const sco = socketConnection();
    setSocket(sco);
    return () => {
      setSocket(null);
    };
  }, []);
  const changeSocket = (data) => {
    const sco = socketConnection();
    setSocket(sco);
    return sco;
  };

  const socketConnection = () => {
    const socketIo = socketIO(API_URL);
    socketIo.emit(
      "storesocket",
      { storeId: localStorage.getItem("_id") },
      async function(e) {
        console.log("errorro o==> ", e);
      }
    );

    return socketIo;
  };
  return [socket, changeSocket];
}

export default useSocket;
